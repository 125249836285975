import React, { useEffect, useState } from 'react';
import { AppDispatch, AppState } from '../store';
import { IconButton, Popper, MenuList, ClickAwayListener, Box, Tooltip, Dialog, DialogTitle, DialogContent, RadioGroup, FormControlLabel, DialogActions, Button, Radio, FormControl, FormGroup, Paper } from '@mui/material';
import { IUserPanelProps } from '../../types';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ApplicationSupportedLanguages, PopperModifier } from '../data/Constants';
import { GetToken, IsAppOpenedByExtApp } from '../services';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleSwitch } from './ToggleSwitch';
import DraggableComponent from './DraggableComponent';
import { setUserPreferences, updateUserPreferences } from '../store/states/UserPreferencesSlice';
/**
 * Renders the user name and a menu to logout
 * @param {IUserPanelProps} props the properties for the user panel component
 * @returns {JSX.Element} the user panel
 */
export const UserPanel = ({ user, onLogout }: IUserPanelProps) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const open = Boolean(anchorElement);
  const [openPreference, setOpen] = React.useState(false);
  const [isShowChecked, setIsShowChecked] = useState(Boolean);
  const [selectLanguage, setSelectLanguage] = useState(String);
  const [disableButton, setDisableButton] = useState(true);
  const [defaultPreferences, setDefaultPreferences] = React.useState(false);
  const { token } = GetToken();

  const userPreferences = useSelector((state: AppState) => state.userPreferences)
  const dispatch = useDispatch<AppDispatch>();

  const onInternalLogout = () => {
    onLogout();
    navigate('/logout', { replace: true });
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!IsAppOpenedByExtApp()) {
      setAnchorElement(anchorElement ? null : event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorElement(null)
  }


  const handleClickOpen = async () => {
    if (userPreferences?.isUserSettingsDefault) {
      setDefaultPreferences(true)
    }
    setSelectLanguage(userPreferences.language)
    setIsShowChecked(userPreferences.showCode)
    setOpen(true);
    setAnchorElement(null)
  };
  const handleClickClose = () => {
    setOpen(false);
    setAnchorElement(null);
    setDisableButton(true);
  };

  useEffect(() => {
    if (isShowChecked !== userPreferences.showCode || selectLanguage !== userPreferences.language) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  })

  const onPreferenceChange = () => {
    setOpen(false);
    setAnchorElement(null);
    setDisableButton(true)
    dispatch(setUserPreferences({ ...userPreferences, preferenceChanged: true, isUserSettingsDefault: false }))
    dispatch(updateUserPreferences(
      {
        token: token,
        request: {
          showCode: isShowChecked,
          language: selectLanguage
        }
      }));
  };
  ApplicationSupportedLanguages.sort((a, b) => a.name.localeCompare(b.name));
  const availableLanguages = ApplicationSupportedLanguages

  return <>
    <span color="secondary">{user} </span>
    <IconButton ref={anchorRef} onClick={handleClick} className="userPanelIconButton">
      <AccountCircleIcon className="userProfileIcon" />
    </IconButton>
    <Popper open={open} anchorEl={anchorElement} placement="bottom" role="tooltip" className="zindex-100"
      modifiers={[
        ...PopperModifier,
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          }
        }
      ]}
    >
      <div role="tooltip">
        <Box component="div" id="arrow" data-popper-arrow className="popperStyle arrow" ref={setArrowRef} />
        <Box >
          <Paper className="popperPage" >
            <ClickAwayListener onClickAway={handleClose}>

              <MenuList
                autoFocusItem={open}
                id="ViewHandler-menu"
                aria-labelledby="SwitchView-button"
              >
                <Box className="preference-container" onClick={handleClickOpen}>
                  <Tooltip title={t('labels.preferences')}>
                    <IconButton > <RoomPreferencesIcon />
                    </IconButton>
                  </Tooltip>
                  <span>{t('labels.preferences')}</span>
                </Box>
                <Box className="logout-container" onClick={onInternalLogout}>
                  <Tooltip title={t('button.logout')}>
                    <IconButton > <LogoutIcon />
                    </IconButton>
                  </Tooltip>
                  <span>{t('button.logout')}</span>
                </Box>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Box>
      </div>
    </Popper>
    <Dialog className="common-dialog-style cursor-move " open={openPreference} PaperProps={{ className: 'preferenceDialog' }} PaperComponent={DraggableComponent} aria-labelledby="draggable-dialog-title" >
      <DialogTitle className="header">
        {t('labels.userPreferences')}
        <IconButton
          aria-label="close"
          onClick={handleClickClose}
          className="closeIcon"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="action-panel-dialog-content" >

        <FormControl className="w-100">
          <FormGroup>

            <fieldset className="inputFieldset w-100 ">
              <legend className="inputLegend" > {t('labels.usability')} </legend>
              <FormControlLabel control={<><ToggleSwitch onChange={() => {
                setIsShowChecked(!isShowChecked); setDefaultPreferences(false);
              }} checked={isShowChecked}
              /> </>} label={t('labels.showCode')}
              />
              {defaultPreferences ? <span className="color-red"> ({t('labels.default')}) </span> : null}
            </fieldset>

            <fieldset className="inputFieldset w-100">
              <legend id="demo-controlled-radio-buttons-group">{t('labels.language')}</legend>
              <div className="radio-group">
                {availableLanguages.map((value: any) =>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={selectLanguage}
                    key={value.code}
                    onChange={(e) => {
                      setSelectLanguage(e.target.value.toString()); setDefaultPreferences(false);
                    }}
                  >
                    <FormControlLabel value={value.code} label={defaultPreferences && value.code === selectLanguage ? <> <span>{value.name + ' (' + value.code + ')'}</span><span className="color-red margin-default">{`(${t('labels.default')})`}</span></> : <span>{value.name + ' (' + value.code + ')'}</span>} control={< Radio />} />
                  </RadioGroup>)}
              </div>
            </fieldset>
          </FormGroup>
        </FormControl>

      </DialogContent>

      <DialogActions >
        <Button variant="contained" className="text-capitalize" disabled={disableButton} onClick={onPreferenceChange} >{t('button.ok')}</Button>
        <Button className="text-capitalize" aria-label="close" onClick={handleClickClose}>{t('button.cancel')}</Button>
      </DialogActions>

    </Dialog>
    <br />
  </>;
}