import { Info } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { IInfoDialogIconProps } from '../../../types';
import { hasOrderingInstruction } from '../../services';
import { InfoDialog } from '../InfoDialog';

export const InfoDialogIcon = ( {variable,type}:IInfoDialogIconProps ) => {
  const [openDialog, setOpenDialog] = useState<boolean>( false );
  const getClassName = ()=>{
    let iconClass = ''
    let iconClassHighlight = ''
    iconClass = type && type === 'FF' ? 'familyInfoDialogIcon' : 'infoDialogIcon';
    const hasHighLight = hasOrderingInstruction( variable.properties ? variable.properties : [] ) || variable?.hasORI ;
    iconClassHighlight = hasHighLight ? 'infoDialogIconHighlight' : '';
    return iconClass + ' ' + iconClassHighlight
  }

  const handleCloseDialog = ()=> {
    setOpenDialog( false )
  }
  
  return <><IconButton 
    data-testid="btn-info-dialog" 
    onClick={ () => {
      setOpenDialog( true )
    } } className={ `${getClassName( )} ` }
  >
    <Info color="primary" />
  </IconButton>
  {openDialog && <InfoDialog variable={ variable } handleCloseDialog={ handleCloseDialog }/> }
  </> || null;
}