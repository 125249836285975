
/**
 * Returns Product Catalog data
 * @param action 
 * @param state 
 * @returns {productCatalog, productCatalogDetails}
 */
export function productCatalogData(action, state) {
  let catalog = {};
  if (action?.productCatalog && action?.pageDetails?.page === 1) {
    catalog = { [action.pageDetails.page]: action.productCatalog };
  } else if (action?.productCatalog) {
    catalog = { ...state.productCatalog };
    if (Object.keys(catalog).length > 4) {
      delete catalog[Object.keys(catalog)[1]]; //To remove the second property when there are 5 entries
    }
    catalog = { ...catalog, [action.pageDetails.page]: action.productCatalog };
  }
  return { productCatalog: action?.productCatalog ? catalog : { ...state.productCatalog }, productCatalogDetails: action.pageDetails ? action.pageDetails : { page: 1, limit: 15, totalRecords: 1, agCodes: [] } }
}

/**
 * Return My Configurations page data
 * @param action 
 * @param state 
 * @returns {myConfigurations, myConfigPageDetails}
 */
export function myConfigurationData(action, state) {
  let configurations = {};
  if (action?.myConfigurations && action?.pageDetails?.page === 1) {
    configurations = { [action.pageDetails.page]: action?.myConfigurations };
  } else if (action?.myConfigurations) {
    configurations = { ...state.myConfigurations };
    if (Object.keys(configurations).length > 4) {
      delete configurations[Object.keys(configurations)[1]]; //To remove the second property when there are 5 entries
    }
    configurations = { ...configurations, [action.pageDetails.page]: action?.myConfigurations };
  }
  return { myConfigurations: action?.myConfigurations ? configurations : { ...state.myConfigurations }, myConfigPageDetails: action.pageDetails ?? { page: 1, limit: 15, totalRecords: 1 } };
}