import { Box,Dialog,DialogContent,DialogTitle, IconButton, Tab} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppStore} from '../store';
import { IFeatureOrFamily,IInfoDialogProps } from '../../types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { getNameWithCode } from '../services';
import CloseIcon from '@mui/icons-material/Close';
import { SalesText } from './SalesText';
import { OrderingInstructions } from './OrderingInstructions';
import { ImageGallery } from './ImageGallery';
import { ETabValue, EVariableType } from '../data/Constants';
import DraggableComponent from './DraggableComponent';

const getTitle = ( variable:IFeatureOrFamily,showCode:boolean ): string =>{
  return variable.variableType === EVariableType.Product ? variable.name : getNameWithCode( variable,showCode );
}

/**
 * Renders short sales text, ordering instructions, images in a dialog
 * @param {IInfoDialogProps } props info dialog props
 * @returns {JSX.Element} the Info dialog popup component
 */
export const InfoDialog = ( props: IInfoDialogProps ) => {
  const {variable, handleCloseDialog} = props
  const [openDialog, setOpenDialog] = useState<boolean>( true )
  const [TabValue, setTabValue] = React.useState<string|null>( 'SalesText' );
  const {t} = useTranslation();

  const showCode = AppStore.getState().userSettings.showCode
  const handleChange = ( ...params:[React.SyntheticEvent, string] ) => {
    setTabValue( params[1] );
  };
  const onClose = () => {
    setOpenDialog( false );
    handleCloseDialog()
  };
  const title = variable ? getTitle( variable,showCode ) : '';


  return <Dialog className="common-dialog-style infoDialogStyle cursor-move" open={ openDialog } onClose={ onClose } PaperProps={ {className:'paper'} } PaperComponent={ DraggableComponent } aria-labelledby="draggable-dialog-title" >
    <DialogTitle className="header title">
      <div className="infoDialogTitle">{title}</div> 
    </DialogTitle>
    <DialogContent >
      <IconButton className="closeIcon"
        aria-label="close"
        onClick={ onClose }
      >
        <CloseIcon />
      </IconButton>
      <TabContext value={ TabValue || ETabValue.SalesText }>
        <Box className="dialog-box">
          <TabList onChange={ handleChange } >
            <Tab className="dialog-tab"
              value={ ETabValue.SalesText } label={ t( 'labels.salesText' ) }
            />
            <Tab className="dialog-tab" 
              value={ ETabValue.OrderingInstructions } label={ t( 'labels.orderingInstructions' ) }
            />
            <Tab className="dialog-tab" 
              value={ ETabValue.Images } label={ t( 'labels.images' ) }
            />
          </TabList>
        </Box>
        <TabPanel className="dialog-panel  sales-text-dialog" value={ ETabValue.SalesText } >
          <SalesText variable={ variable }/>
        </TabPanel>
        <TabPanel className="dialog-panel ordering-instructions-dialog " value={ ETabValue.OrderingInstructions }>
          <OrderingInstructions variable={ variable }/>
        </TabPanel>
        <TabPanel className="dialog-panel ordering-instructions-dialog productImageDialog" value={ ETabValue.Images }>
          <ImageGallery variable={ variable }/>
        </TabPanel>
      </TabContext>
    </DialogContent>
  </Dialog> || null
}