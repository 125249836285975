import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../store';
import { getLanguageCode, getConfigureRequest, IsAppOpenedInIframe, IsAppOpenedByExtApp } from '../services';
import { ViewIdError } from './ViewIdError'
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { countryCodes as codes } from '../localization';
import { IError, ICountry } from '../../types';
const countryCodes: ICountry = codes.codes;
import CancelIcon from '@mui/icons-material/Cancel';
import { AppAuthContext } from '../services/Contexts';
import { UrlHelper } from '../services/UrlHelper';
import { ESessionStore, EUrlParams, EErrorCodes, EErrorTypes, EErrorPage } from '../data/Constants';
import { useAuth } from 'oidc-react';
import { SessionStore } from '../services/SessionStore';
import { resetError } from '../store/states/ErrorSlice';
import { configure, validViewId } from '../store/states/ConfigurationSlice';
  
/**
   * Renders the ErrorDialog when the viewId from the punchout api is invalid 
   * @param {any} props the properties for the Error dialog 
   * @returns {JSX.Element} the Error dialog
   */
  
const display404 = ( error: IError, view: string, setView: () => void, t: ( arg: string, arg2?: object ) => string, token: string ) => {
  if ( error.page === 'configure' ) {
    return <AppAuthContext.Provider value={ token }> <ViewIdError handleContact={ handleContact } view={ view } setView={ setView } /></AppAuthContext.Provider>
  } else if ( error.page === 'noViews' ) {
    return <span>{t( 'errorMessages.viewId.noViews' )}</span>
  } else if ( error.page === 'create-configuration' ) {
    return <span >{t( 'errorMessages.general.PKGNF' )}</span>
  } else {
    return <span >{error.message ? error.message : t( 'errorMessages.general.404' )}</span>
  }
}
  
const display400 = ( error: IError, t: ( arg: string, arg2?: object ) => string, view: string, setView: () => void, token: string ) => {
  if ( error.message && typeof error.message === 'string' && error.message.indexOf( 'MRKTNF' ) >= 0 ) {
    return <span>{t( 'errorMessages.general.MRKTNF', { country: countryCodes[error.data.country].name + ' (' + error.data.country + ')' } )}</span>
  } else if ( error.page === 'configure' ) {
    return display404( error, view, setView, t, token );
  } else {
    return <span >{t( 'errorMessages.general.400' )}</span>
  }
}
  
const displayMessage = ( error: IError, view: string, setView: () => void, t: ( arg: string, arg2?: object ) => string, token: string ) => {
  switch ( error.code ) {
    case 401: return <span >{t( 'errorMessages.general.401' )}</span>
    case 204: return <span >{error.message ? error.message : t( 'errorMessages.general.204' )}</span>
    case 400: return display400( error, t, view, setView, token )
    case 404: return display404( error, view, setView, t, token )
    case 403: return <span>{t( 'errorMessages.general.403' )}</span>
    default:
      return <span >{t( 'errorMessages.general.500' )}</span>;
  }
}
  
const getHeader = ( error: IError, productSettings, t: ( arg: string, arg2?: object ) => string ) => {
  if ( error.code === 400 && error.page === 'create-configuration' ) {
    return <span className="errorProduct-header">{error.data.productId + ' - ' + productSettings.productCatalog[productSettings.productCatalogDetails.page]?.find( prod => prod.productId === error.data.productId ).productDescription}</span>
  }
  return <span >&nbsp; {t( 'dialogBox.error' )}</span>
}
  
const GetToken = () => {
  let auth;
  let token = '';
  if ( IsAppOpenedInIframe() || IsAppOpenedByExtApp() ) {
    token = UrlHelper.getSearchParameter( EUrlParams.Token ) || SessionStore.get( ESessionStore.Token );
  } else {
    auth = useAuth();
    token = auth.userData?.access_token as string;
  }
  return { token: token, auth: auth };
}
  
const handleContact = ( navigate: ( path: string, second: object ) => void, dispatch: AppDispatch ) => {
  navigate( '/Error', { replace: true, state: { type: EErrorTypes.ContactUS } } );
  dispatch( resetError() );
}
  
const closeDialog = ( error, auth, dispatch: AppDispatch ) => {
  if ( error.code === 401 ) {
    return auth.signIn();
  } else if ( error.code === 204 && error.page === EErrorPage.Configurator ) {
    window.location.reload();
  } else {
    return dispatch( resetError() );
  }
  
}
  
const getLabel = ( error, t: any ) => {
  return error.code === 204 && error.page === EErrorPage.Configurator ? t( 'button.refresh' ) : t( 'button.ok' )
}
  
const handleError = ( error, navigate, dispatch: AppDispatch ) => {
  if ( ( IsAppOpenedByExtApp() || IsAppOpenedInIframe() ) && error.code === EErrorCodes.Unauthenticated ) {
    navigate( '/Error', { replace: true, state: { type: EErrorTypes.NotAuthenticated } } );
    dispatch( resetError() )
  }
  if ( error.code === EErrorCodes.Unautorized ) {
    navigate( '/Error', { replace: true, state: { type: EErrorTypes.NotAuthorized } } );
    dispatch( resetError() );
  }
}
  
export const ErrorDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { token, auth } = GetToken();
  
  const { t } = useTranslation();
  
  //selectors
  const error = useSelector( ( state: AppState ) => state.error );
  const configuration = useSelector( ( state: AppState ) => state.configuration )
  
  const setView = () => {
    const request = getConfigureRequest( configuration );
    if ( !request ) {
      return;
    }
    dispatch( configure( { request: request, token: token, language: getLanguageCode() } ) );
    // To Reset ErrorDialog after clicking ok
    dispatch( resetError() )
    dispatch( validViewId() )
  }
  const productSettings = useSelector( ( state: AppState ) => state.productCatalog );
  
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect( () => {
    handleError( error, navigate, dispatch )
  }, [error] );
  // To hide error dialog when configuration id is invalid
  if ( ( error.code === 404 || error.code === 400 ) && error.page === 'noViews' ) {
    return null;
  }
  const view = searchParams.get( 'viewId' ) || '';
  if ( error.code ) {
    return (
      <Dialog className="common-dialog-style error-dialog-style" open={ error.code ? true : false } maxWidth="md">
        <DialogTitle className=" header title">
          <Grid container alignItems="center" className="error-header">
            <CancelIcon className="errorIcon" />
            {getHeader( error, productSettings, t )}
          </Grid>
        </DialogTitle>
        <DialogContent className="dialogContent" >
          <br />
          {displayMessage( error, view, setView, t, token )}
        </DialogContent>
        <DialogActions>
          {( error.code === 404 || error.code === 400 ) && error.page === 'configure' ?
            ''
            :
            <>
              <Button className="close-dialog text-capitalize" onClick={ () => handleContact( navigate, dispatch ) } data-testid="contactUs-btn">
                <span>{t( 'button.contactUS' )}</span>
              </Button>
              <Button className="close-dialog text-capitalize" onClick={ () => closeDialog( error, auth, dispatch ) } data-testid="close-btn">
                <span>{getLabel( error, t )}</span>
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>
    );
  } else {
    return <></>
  }
};
  