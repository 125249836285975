import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UserSettingsApi } from '../../api/UserSettingsApi';
import { IAuthorizationRequest, IMyConfigurationRequest } from '../../../types/IApiTypes';
import { myConfigurationData } from '../../store/Helpers/ProductSettingsSliceHelper';
import { UserApi } from '../../api/UserApi';
import { IUser } from '../../../types/ITypes';


const initialState: IUser = {
  details: {},
  myConfigurations: {},
  myConfigPageDetails: { page: 1, limit: 15, totalRecords: 1 },
  category: { channels: [], applications: [] },
  loading: false
}

//Gets User logged in details
export const getUserDetails = createAsyncThunk('user/getUserDetails', async (action: IAuthorizationRequest) => {
  return UserSettingsApi.getUserDetails(action);
});

//Gets all the user specific configurations
export const getMyConfigurations = createAsyncThunk('user/getMyConfigurations', async (action: IMyConfigurationRequest) => {
  return UserApi.myConfigurations(action);
});

//Gets the channels that are available
export const getCategories = createAsyncThunk('user/getCategories', async (action: IAuthorizationRequest) => {
  return UserApi.getCategories(action);
});


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateMyConfigPageDetails(state, action) {
      state.myConfigPageDetails = { page: action.payload.page, limit: action.payload.limit, totalRecords: action.payload.totalRecords };
    },
    resetMyConfigurations(state) {
      state.myConfigurations = {};
      state.category = { channels: [], applications: [] };
      state.myConfigPageDetails = { page: 1, limit: 15, totalRecords: 1 };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.details = action.payload.data
      }
    });

    builder.addCase(getMyConfigurations.fulfilled, (state, action) => {
      if (action.payload) {
        const configurationData = myConfigurationData(action.payload, state);
        state.myConfigurations = configurationData.myConfigurations;
        state.myConfigPageDetails = { ...state.myConfigPageDetails, ...configurationData.myConfigPageDetails }
      }
    });


    builder.addCase(getCategories.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getCategories.fulfilled, (state, action) => {
      if (action.payload) {
        state.loading = false;
        state.category = action.payload.category;
      }
    });
  }
})

export const { updateMyConfigPageDetails, resetMyConfigurations } = userSlice.actions;

export default userSlice.reducer;

