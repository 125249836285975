import AxiosClient from './AxiosClient';
import { getHeaders, handleError } from './HttpUtil';
import { handleSuccess } from '../services/ConfigurationDataHelperFunctions';
import { IApiError, IAuthorizationRequest, IUserPreferenceRequest } from '../../types/IApiTypes';

export const UserSettingsApi = {
  getUserPreferences: ( action: IAuthorizationRequest ) => {
    return AxiosClient
      .get( '/user/v1/preferences/read',
        { headers: getHeaders( action.token ).headers }
      ).then( ( response ) => {
        return handleSuccess( response )
      } ).catch( () => null ) //If get User settings failed, default or fall back settings would be used
  },

  updateUserPreferences: ( action: IUserPreferenceRequest ) => {
    return AxiosClient
      .post( '/user/v1/preferences/set',
        action.request,
        { headers: getHeaders( action.token ).headers } 
      ).then( () => {
        return {
          data: action.request
        }
      } ).catch( ( err:IApiError ) => handleError( err ) )
  },

  getUserDetails: ( action:IAuthorizationRequest ) => {
    return AxiosClient
      .get( '/user/v1/details',
        { headers: getHeaders( action.token ).headers }
      ).then( ( response ) => {
        return handleSuccess( response )
      } ).catch( ( err:IApiError ) => {
        return handleError( err );
      } )
  }
}