import React, { ReactNode, memo } from "react"
import TreeItemComponent from "./TreeItemComponent"
import { ITreeNode } from "../../types"

/**
 * Prepare the parent and chield list of simple tree view with tree item component
 * @props name, code, treeItems, children 
 * @returns {JSX.Element} a  list of TreeItem component.
 */
const TreeItemGroupComponent = ({ name, code, treeItems, children }: { name: string, code: string, treeItems: Array<ITreeNode>, children?: ReactNode }) => {
    return (
        <>
            <div className="tree-group">
                <TreeItemComponent name={name} code={code}>
                    {treeItems?.map((element: ITreeNode) => {
                        return <TreeItemComponent key={element.code} name={element.name} code={element.code} />
                    })}
                </TreeItemComponent>
                {children}
            </div>
        </>
    )
}

export default memo(TreeItemGroupComponent);