import {
  FormControl,
  Tooltip
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { getNameWithCode } from '../../services';
import {
  SingleValueSelectControl,
  MultiValueSelectControl,
  NumericControl,
  StringControl,
  SubModelControl,
  DateControl
} from '..';
import { IFeatureOrFamily } from '../../../types/IHelperTypes';
import { IValueType, IVariableInputProps,IExtendedConfigureResponse } from '../../../types';
import { InfoDialogIcon } from './InfoDialogIcon';
import { checkIsOptionReadonly,checkIsOptionHidden } from '../../services/ConfigurationDataHelperFunctions';
import { InCompleteIcon } from './InCompleteIcon';
import { EVariableType,EConfigurationType } from '../../data/Constants';
import { AppStore } from '../../../src/store';


/**
   * Renders the input component for the given variable.
   * Decides based on the variable which input to use.
   * Handles readonly mode and showCode/hideCodes functionality
   * @param {IVariableInputProps} props the properties for the dynamic input component
   * @returns {JSX.Element} the most suitable input for the given variable
   */


export const DynamicInputControl = ( { variable, onChange, onOptionalChange }: IVariableInputProps ) => {
  const showCode = useSelector( ( state: AppState )=> state.userSettings.showCode )
  const featureFlags = AppStore.getState().claimsData.featureFlags;

  const isGuarded = variable.isGuarded;
  const config: IExtendedConfigureResponse = AppStore.getState().configuration;
  const disableInputControl = ()=>{
    return config.savedConfiguration?.state === EConfigurationType.ReadOnly 
          || config.access === EConfigurationType.ReadOnly 
          || isGuarded || checkIsOptionReadonly( variable ) || variable.isReadOnly;
  }
  const disabled = disableInputControl();
  const type = variable.variableType ;
  const singletonValue = variable.values?.find( v => v.state.isAssigned );
  const value = ( singletonValue ? singletonValue.value : '' ) as string;
  const label = getNameWithCode( variable as IFeatureOrFamily, showCode );
  const hidden = checkIsOptionHidden( variable )  
  /*
    If hidden/suppressed return null. Return null will not show any input control for particular variable in UI.
    It doesn't have any impact on configuration summary, price or any other functionality of application
  */  

  if ( variable.suppressed || hidden ) {
    return null;
  }

  const onInternalChange = ( v: IValueType ) => onChange && onChange( variable, v );

  const renderFormControl = ( component: JSX.Element ) => {
    // const checkboxlabel = singletonValues.length && getNameWithCode( singletonValues[0] as IFeatureOrFamily,userSettings.showCode ) || '';
    return <fieldset className={ variable.isSearchValueMatched ? 'inputFieldsetWithSearchValueMatched' : 'inputFieldset' }>
      <Tooltip title={ label } placement="right-start">
        <legend className="inputLegend"><span>{label}</span> {variable.complete === false ? <InCompleteIcon/> : ''}
          <InfoDialogIcon variable={ variable as IFeatureOrFamily } type="FF"/>
        </legend>
      </Tooltip>
      <FormControl fullWidth variant="outlined" disabled={ disabled }>
        { component }
      </FormControl>
    </fieldset>

  }

  switch ( type ) {
    case EVariableType.Submodel:
      return renderFormControl( 
        <SubModelControl 
          key={ variable.id } 
          variable={ variable } 
          label={ label } 
          value={ value } 
          onChange={ onInternalChange } 
          disabled={ !!disabled }
        />
      );
    case EVariableType.Date:
      return renderFormControl( 
        <DateControl 
          featureFlags={ featureFlags } 
          key={ variable.id } 
          variable={ variable } 
          label={ label } 
          value={ value } 
          onChange={ onInternalChange } 
          onOptionalChange={ onOptionalChange }
        />
      );
    case EVariableType.Singleselect:
      return renderFormControl( 
        <SingleValueSelectControl 
          featureFlags={ featureFlags } 
          key={ variable.id } 
          variable={ variable } 
          label={ label } 
          values={ variable.values } 
          value={ value } 
          onChange={ onInternalChange } 
          onOptionalChange={ onOptionalChange } 
          disabled={ disabled }
          showCode={ showCode }
        />
      );
    case EVariableType.Multiselect:
      return renderFormControl( 
        <MultiValueSelectControl 
          featureFlags={ featureFlags } 
          key={ variable.id } 
          variable={ variable } 
          label={ label } 
          values={ variable.values } 
          onChange={ onInternalChange } 
          onOptionalChange={ onOptionalChange } 
          disabled={ disabled }
          showCode={ showCode }
        />
      );
    case EVariableType.Number:
      return renderFormControl( 
        // <OnTimeoutChanger timeout={ 1000 } onChange={ onInternalChange }>
        <NumericControl 
          featureFlags={ featureFlags } 
          key={ variable.id } 
          variable={ variable } 
          label={ label } 
          value={ value } 
          disabled={ disabled } 
          onChange={ onInternalChange }
          onOptionalChange={ onOptionalChange }
        />
        // </OnTimeoutChanger>
      );
    default:
      return renderFormControl( 
        // <OnTimeoutChanger timeout={ 1000 } onChange={ onInternalChange }>
        <StringControl 
          featureFlags={ featureFlags } 
          key={ variable.id } 
          variable={ variable } 
          label={ label } 
          value={ value } 
          properties={ variable.properties } 
          disabled={ disabled } 
          onChange={ onInternalChange }
          onOptionalChange={ onOptionalChange }
        />
        // </OnTimeoutChanger>
      );
  }
};
