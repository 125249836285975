import { EFilterRootOption } from "../../data/Constants";
import { ITreeNode, IFilterOption, IFilterNode, IMyConfigurationFilter, IProdcutFilter, IProduct, IProductFilterOptions, ISimpleTreeNode } from "../../../types";

const configNameRegEx = /(^((\([A-Za-z0-9]+\))+|([A-Za-z0-9]+))+(((-|_| )[A-Za-z0-9]+)*(( |-|_)?(\([A-Za-z0-9]+\))+)*)*)$/;

/**
 * This function do the add filter selection and desection includion root node.
 * @param itemIds 
 * @param treeNode 
 * @param filteredCodes 
 * @param availbleFilterCodes 
 * @returns selectedCodes
 */
export const handleSelectionOfMultiSelectOnChange = (itemIds: Array<string>, treeNode: ISimpleTreeNode, filteredCodes: IFilterOption, availbleFilterCodes: IFilterOption): IFilterOption => {
  const selectedCodes = { ...filteredCodes };
  if (itemIds.includes(treeNode.enumValue) && !filteredCodes[treeNode.type].includes(treeNode.enumValue)) {
    selectedCodes[treeNode.type] = [...availbleFilterCodes[treeNode.type], treeNode.enumValue];
  } else if (itemIds.includes(treeNode.enumValue) && selectedCodes[treeNode.type].includes(treeNode.enumValue)) {
    selectedCodes[treeNode.type] = itemIds.filter((code) => code != treeNode.enumValue);
  } else if (!itemIds.includes(treeNode.enumValue) && selectedCodes[treeNode.type].includes(treeNode.enumValue)) {
    selectedCodes[treeNode.type] = [];
  } else if (!itemIds.includes(treeNode.enumValue) && itemIds.length === availbleFilterCodes[treeNode.type].length) {
    selectedCodes[treeNode.type] = [...availbleFilterCodes[treeNode.type], treeNode.enumValue];
  } else {
    selectedCodes[treeNode.type] = [...itemIds];
  }
  return selectedCodes;
}

/**
 * This Function helps prepare the filter codes that are availbe for product portfolio
 * @param productFilter 
 * @returns filterCodes
 */
export const prepareTreeCodes = (productFilter: { channels: IFilterNode[], business: ITreeNode[] }): IProdcutFilter => {
  const filterCodes: IProdcutFilter = { channels: [], business: [] };
  if (!productFilter) {
    return filterCodes;
  }
  productFilter.channels.forEach((node: IFilterNode) => {
    filterCodes.channels.push(node.code);
  })

  productFilter.business.forEach((node: ITreeNode) => {
    filterCodes.business.push(node.code);
  });

  return filterCodes;
}

/**
 * This Function helps prepare the filter codes that are availbe for my configuration
 * @param myConfigurationFilter 
 * @returns filterCodes
 */
export const prepareMyConfigurationTreeCodes = (myConfigurationFilter: { channels: IFilterNode[], applications: IFilterNode[] }): IMyConfigurationFilter => {
  const filterCodes: IMyConfigurationFilter = { channels: [], applications: [] };
  if (!myConfigurationFilter) {
    return filterCodes;
  }
  myConfigurationFilter.channels.forEach((node: IFilterNode) => {
    filterCodes.channels.push(node.code);
  })

  myConfigurationFilter.applications.forEach((node: IFilterNode) => {
    filterCodes.applications.push(node.code);
  });

  return filterCodes;
}

/**
 * This function helps prepare the contries availbe for the selected product.
 * @param prodList 
 * @param productId 
 * @returns countries || []
 */
export const getAvailableCountries = (prodList: IProduct[], productId: string): IProduct['countries'] | [] => {
  const productData: IProduct | undefined = prodList.find(x => {
    return x.productId === productId;
  })
  return productData ? productData.countries : [];
}

/**
 * This function add the agcodes of the selected business filter item to send in the request payload
 * @param prodFilter 
 * @param selectedCodes 
 * @param type 
 * @returns selectedCodes
 */
export const getSelectedCodesWithAgCodes = (prodFilter: IProductFilterOptions, selectedCodes: IProdcutFilter, type: string): IProdcutFilter => {
  if (type === EFilterRootOption.Business.toLowerCase()) {
    prodFilter.business.forEach((item: ITreeNode) => {
      if (selectedCodes[type].includes(item.code) && item.articleGroups) {
        selectedCodes[type] = [...selectedCodes[type], ...item.articleGroups];
      }
    })
  }
  return selectedCodes;
}

/**
 * This section helps to get the name of the selected filter item, to show in the collapsed mode
 * @param treeItem 
 * @param selectedCodes 
 * @returns namesForShow
 */
export const getSelectedCodeNames = (treeItem: ISimpleTreeNode, selectedCodes: IFilterOption): Array<string> | [] => {
  const namesForShow: Array<string> = [];
  treeItem.treeItems.forEach((item: ITreeNode) => {
    if (selectedCodes[treeItem.type].includes(item.code)) {
      namesForShow.push(item.name);
    }
  });
  return namesForShow;
}

/**
 * This function remove the rootnode from the selected code.
 * @param selectedCodes 
 * @param removeCode 
 * @returns selectedCodes
 */
export const filterIds = (selectedCodes: Array<string>, removeCode: string): Array<string> => {
  selectedCodes = selectedCodes.length > 0 ? selectedCodes.filter((selectedCode: string) => selectedCode != removeCode) : selectedCodes;
  return selectedCodes;
}

/**
 * This function helps to find if the response is empty or not to show the no data found message
 * @param filterOptions 
 * @returns true || false
 */
export const isFilterDataAvailable = (filterOptions: IProductFilterOptions): boolean => {
  return filterOptions.business.length > 0 || filterOptions.channels.length > 0 ? true : false;
}

/**
 * This Function helps to validate the configuration name while crete configuration.
 * @param name 
 * @returns true || false
 */
export const validateName = (name: string): boolean => {
  return name === '' || name.length > 2 && name.length <= 40 && configNameRegEx.test(name)
}