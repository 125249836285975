import React, { lazy, Suspense, useState } from 'react';
import { AppDispatch, AppState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab } from '@mui/material';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import { DefaultFlow, ESessionStore, ETabValue } from '../../data/Constants';
import { LoadingScreen, Unauthenticated, UnsupportedBrowser } from '..';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { SessionStore } from '../../services/SessionStore';
import { appSettings } from '../../settings';
import { changeLandingTab } from '../../store/states/ConfigurationSlice';
const ProductPortfolio = lazy(() => import('./ProductPortfolio/ProductPortfolio'));
const MyConfigurations = lazy(() => import('./MyConfiguration/MyConfigurations'));

/**
 * This componet create Tabs for the landing page
 * @returns {JSX.Element}
 */
export const LandingPage = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  //selectors
  const configuration = useSelector((state: AppState) => state.configuration);

  const [tabValue, setTabValue] = useState<string>(ETabValue.Product);

  if (!configuration) {
    return null;
  }


  SessionStore.set(ESessionStore.Flow, DefaultFlow);
  SessionStore.set(ESessionStore.Scope, appSettings.ApiKey);

  /**
   * This function perform the tab change action
   * @param params 
   * @returns void
   */
  const handleTabChange = (...params: [React.BaseSyntheticEvent, string]): void => {
    setTabValue(params[1]);
    dispatch(changeLandingTab({ value: true, tabValue: tabValue === ETabValue.Product ? ETabValue.MyConfiguration : ETabValue.Product }));
  };

  if (!auth || !auth.userData) {
    return <Unauthenticated />;
  }


  return <Box className="landing-Page">
    <TabContext value={tabValue}>

      <Box className="dialog-box">
        <TabList onChange={handleTabChange} >
          <Tab className="dialog-tab text-capitalize" data-testid="product-portfolio"
            value={ETabValue.Product} label={t('landingPage.productPortfolio')}
          />
          <Tab className="dialog-tab text-capitalize" data-testid="my-configuration"
            value={ETabValue.MyConfiguration} label={t('landingPage.myConfigurations')}
          />
        </TabList>
      </Box>

      <TabPanel value={ETabValue.Product} className="product-portfolio" >
        <Suspense fallback={<LoadingScreen />}>
          <ProductPortfolio />
        </Suspense>
      </TabPanel>

      <TabPanel className="myConfiguration-tab" value={ETabValue.MyConfiguration} >
        <Suspense fallback={<LoadingScreen />}>
          <MyConfigurations />
        </Suspense>
      </TabPanel>

    </TabContext>
    <UnsupportedBrowser />
  </Box>
}
