import { IAuthorizationRequest, ICurrencyRequest, IPriceRequest } from '../../types/IApiTypes';
import { getPackagePath, getProductId } from '../services';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';


export const PriceApi = {
  price: ( action: IPriceRequest ) => {
    const productModel = action.modelId ? action.modelId : getProductId();
    return AxiosClient
      .get( '/product/v1/price/read',
        {
          params:{ 
            packagePath:getPackagePath( getProductId() ), country:action.countryCode, 
            currency:action.currencyCode, productModel:productModel 
          },
          headers: getHeaders( action.token ).headers
        }
      ).then( ( response ) => {
        return {
          price: response.data
        }
      } ).catch( () => {
        return null
      } )
  },

  currency: ( action: ICurrencyRequest ) => {
    return AxiosClient
      .get( '/product/v1/currency/read',
        {
          params:{ packagePath:getPackagePath( getProductId() ), country:action.countryCode },
          headers: getHeaders( action.token ).headers
        }
      ).then( ( response ) => {
        return {
          currency: response.data
        }
      } ).catch( () => {
        return null
      } )
  },

  priceListMap: ( action: IAuthorizationRequest ) => {
    return AxiosClient
      .get( '/product/v1/priceList/read',
        { params:{ packagePath: getPackagePath( getProductId() ) },
          headers: getHeaders( action.token ).headers
        }
      ).then( ( response ) => {
        return response.data.priceMaps
      } ).catch( () => {
        return null
      } )
  }
}