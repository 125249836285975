import React, { lazy, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../store';
import { Box, Button, List, Grid, Pagination, Stack } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import CreateConfigurationForm from './CreateConfigurationForm';
import { SelectComponent } from '../../InputComponents/SelectComponent';
import { ProductCardComponent } from './ProductCardComponent';
import { ETabValue, RecordsPerPage, EFilterRootOption, EGeneralInfo } from '../../../data/Constants';
import { getProductCatalog, getProductFilter, setProductCatalogDetails } from '../../../store/states/ProductCatalogSlice';
import { getUserPreferences } from '../../../store/states/UserPreferencesSlice';
import { changeLandingTab } from '../../../store/states/ConfigurationSlice';
import { getClaims } from '../../../store/states/ClaimsDataSlice';
import { filterIds, getAvailableCountries, getSelectedCodesWithAgCodes, isFilterDataAvailable, prepareTreeCodes } from '../../../store/Helpers/LandingHelper';
import { IProduct, IProductFilterOptions, ISimpleTreeProps, IProdcutFilter, IConfigDialogProp } from '../../../../types';
import { getUserDetails } from '../../../store/states/UserSlice';
import { IProductCatalogRequest } from '../../../../types/IApiTypes';

const SimpleTreeViewComponent = lazy(() => import('../../SimpleTreeViewComponent'));

/**
 * This component perform the role to show the product portflio list, filter and pagination.
 * @returns {JSX.Element}
 */
const ProductPortfolio = () => {
  const initialFilterOptionValue = { channels: [], business: [] };
  const auth = useAuth();
  const token = auth.userData?.access_token || '';
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  //selectors
  const productSettings = useSelector((state: AppState) => state.productCatalog);
  const configuration = useSelector((state: AppState) => state.configuration)

  const [productId, setProductId] = useState<string>('');
  const [prodList, setProdList] = useState<IProduct[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogProdDesc, setDialogProdDesc] = useState<string>('');
  const [countriesAvailable, setCountriesAvailable] = useState<IProduct['countries']>([]);
  const [catalogPage, setCatalogPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(RecordsPerPage[0]); //Default records size is 15
  const [totalCatalogRecords, setTotalCatalogRecords] = useState<number>(1); //Default records size is 1
  const [filterOptions, setFilterOptions] = useState<IProductFilterOptions>(initialFilterOptionValue);
  const [availbleFilterCodes, setAvailbleFilterCodes] = useState<IProdcutFilter>(initialFilterOptionValue);
  const [filteredCodes, setFilteredCodes] = useState<IProdcutFilter>(initialFilterOptionValue);

  /**
   * fetch the userDetails, userPrefrences, claims, productCatalog and product filter on initial load
   */
  const loadInitialData = () => {
    const { page, limit } = productSettings.productCatalogDetails;
    dispatch(getUserDetails({ token: token }));
    dispatch(getUserPreferences({ token: token }));
    dispatch(getClaims({ token: token }));
    fetchProductCatalog({ page: page, limit: limit, productSearch: '' });
    fetchProductFilter();
  }

  /**
   * fetch the product catelog
   * @param page, limit, productSearch, selectedCodes 
   */
  const fetchProductCatalog = ({ page, limit, productSearch, selectedCodes = filteredCodes }: { page: number, limit: number, productSearch: string, selectedCodes?: IProdcutFilter }) => {
    const business = filterIds(selectedCodes.business, EFilterRootOption.Business);
    const channels = filterIds(selectedCodes.channels, EFilterRootOption.Channel);
    const params: IProductCatalogRequest = {
      token: token,
      page: page,
      limit: limit,
      productSearch: productSearch,
      agCodes: business,
      channelCodes: channels
    }
    dispatch(getProductCatalog(params));
  }

  /**
   * Fetch the product filter
   */
  const fetchProductFilter = () => {
    dispatch(getProductFilter({ token: token }));
  }

  /**
   * Called on record per page change performed
   * @param event 
   * @returns void
   */
  const handleChangeForRPP = (event: React.ChangeEvent) => {
    const { value } = event.target;
    setRecordsPerPage(value);
    fetchProductCatalog({ page: 1, limit: value, productSearch: configuration.productSearchValue });
  }

  /**
   * Call the catalog, filter and set the initial state on refresh
   */
  const handleRefresh = () => {
    setFilteredCodes(initialFilterOptionValue);
    dispatch(changeLandingTab({ value: true, tabValue: ETabValue.Product }))
    fetchProductCatalog({ page: 1, limit: recordsPerPage, productSearch: '', selectedCodes: initialFilterOptionValue });
    fetchProductFilter();
  }

  /**
  * Trigger on change of filter tree from the left pane
  * @param selectedCodes 
  * @param type 
  * @return void
  */
  const handleFilteredCodes = (selectedCodes: IProdcutFilter, type: string): void => {
    const { page, limit } = productSettings.productCatalogDetails;
    setFilteredCodes(selectedCodes);
    selectedCodes = getSelectedCodesWithAgCodes(filterOptions, { ...selectedCodes } as IProdcutFilter, type);
    fetchProductCatalog({ page: page, limit: limit, productSearch: configuration.productSearchValue, selectedCodes: selectedCodes });
  }

  /**
   * Called on pagination change
   * @param _event 
   * @param value 
   * @requires void
   */
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number): void => {
    const { business, channels } = filteredCodes;
    const { totalRecords } = productSettings.productCatalogDetails;
    if (!productSettings.productCatalog[value]) {
      fetchProductCatalog({ page: value, limit: recordsPerPage, productSearch: configuration.productSearchValue });
    } else {
      setProdList(productSettings.productCatalog[value]);
    }
    setCatalogPage(value);
    dispatch(setProductCatalogDetails({ page: value, limit: recordsPerPage, totalRecords: totalRecords, agCodes: business, channelCodes: channels }))
  }

  /**
   * Open dialog to configure the product
   * @param prod 
   */
  const openDialog = (prod: { productId: string, productDescription: string }) => {
    setProductId(prod.productId);
    setDialogProdDesc(`${prod.productDescription}`);
    setShowDialog(true);
  }

  /**
   * Close the Dialog
   */
  const closeDialog = () => {
    setShowDialog(false);
  }

  /**
   * Call on token change to load the initial data
   */
  useEffect(() => {
    if (token) {
      loadInitialData();
    }
  }, [token])

  /**
   * Update the state on change of productFilterOptions or productCatalog
   */
  useEffect(() => {
    const { page, limit, totalRecords } = productSettings.productCatalogDetails;
    setCatalogPage(page);
    setRecordsPerPage(limit);
    setTotalCatalogRecords(Math.ceil(totalRecords / limit) || 1);
    setProdList(productSettings.productCatalog[page] || []);
    if (productSettings.productFilterOptions) {
      setFilterOptions(productSettings.productFilterOptions);
    }
    const availbeFilterCodes = prepareTreeCodes(productSettings.productFilterOptions);
    setAvailbleFilterCodes(availbeFilterCodes);
  }, [productSettings.productFilterOptions, productSettings.productCatalog])

  /**
   * Set the filtered countryData on change of product id
   */
  useEffect(() => {
    const countryData = getAvailableCountries(prodList, productId);
    setCountriesAvailable(countryData);
  }, [productId])

  /**
     * The filterTreeProps is the schema for the creating filter tree on the product portfolio let pane
     */
  const filterTreeProps: ISimpleTreeProps = {
    treeNodes: [{
      type: EFilterRootOption.Channel.toLowerCase(),
      enumValue: EFilterRootOption.Channel,
      label: t('labels.channel'),
      expanded: EFilterRootOption.Channel,
      treeItems: filterOptions.channels
    },
    {
      type: EFilterRootOption.Business.toLowerCase(),
      enumValue: EFilterRootOption.Business,
      label: t('labels.business'),
      expanded: EFilterRootOption.Channel,
      treeItems: filterOptions.business
    }],
    availableFilterCodes: availbleFilterCodes,
    filteredCodes: filteredCodes,
    initialExpandValue: { channels: true, business: false },
    filterDataAvailable: isFilterDataAvailable(filterOptions),
  }

  return <Box className="product-portfolio-container">
    <Box className="hierarchy-container" style={{ marginTop: '25px' }}>
      {productSettings.loading ? <div className="loader-placeholder">{t('loaderMessage.default')} ...</div> :
        <SimpleTreeViewComponent filterTreeProps={filterTreeProps} handleChanges={handleFilteredCodes} />
      }
    </Box>

    {productSettings.productCatalog[productSettings.productCatalogDetails.page]?.length === 0 ? <span className="noProduct-label">{t('landingPage.noProductFound')}</span> :
      <Box className="list-Section-Tab list-Section-Tab::-webkit-scrollbar">
        <Box className="recordsChange-conatiner">
          <SelectComponent name="recordsPerPage" elementAttr='recordsPerPage' value={recordsPerPage} options={RecordsPerPage} onChangeCallBack={handleChangeForRPP} />
          <Button className="refresh-button common-btn" color="primary" variant="text" startIcon={<RefreshIcon />}
            onClick={() => {
              handleRefresh()
            }}
          >
            {t('button.refresh')}
          </Button>
        </Box>
        <Box className="list-Section list-Section::-webkit-scrollbar">
          <List className="list-Items " >
            <Grid container spacing={1}
              className="list-ItemsContainer"
            >
              {prodList.map((prod: IConfigDialogProp, index: number) =>
                <ProductCardComponent key={index} prod={prod} openDialog={openDialog} />
              )}
            </Grid>
          </List>
        </Box>

        <Stack spacing={1} className="catalog-pagination">
          <Pagination color="primary" count={totalCatalogRecords} page={catalogPage} onChange={handleChange} />
        </Stack>

      </Box>
    }
    {showDialog && <CreateConfigurationForm countriesAvailable={countriesAvailable} productId={productId} dialogProdDesc={dialogProdDesc} productSearchValue={configuration.productSearchValue} closeDialog={closeDialog} showDialog={showDialog} />}
  </Box>
}

export default memo(ProductPortfolio);