import { Alert, AlertTitle, Autocomplete, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ICreateConfigurationForm } from '../../../../types';
import DraggableComponent from "../../DraggableComponent";
import { useAuth } from "oidc-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { changeLandingTab, createConfiguration, onLoadCheck } from "../../../store/states/ConfigurationSlice";
import { EConfigurationType, ETabValue, EUrlParams } from "../../../data/Constants";
import { setError } from "../../../store/states/ErrorSlice";
import { validateName } from "../../../store/Helpers/LandingHelper";
import { useTranslation } from "react-i18next";

/**
 * This component perform the role to show the create configuration form.
 * @props countriesAvailable, productId, dialogProdDesc, showDialog
 * @callback closeDialog
 * @returns {JSX.Element}
 */
const CreateConfigurationForm = ({ countriesAvailable, productId, dialogProdDesc, closeDialog, showDialog }: ICreateConfigurationForm) => {
    const auth = useAuth();
    const token = auth.userData?.access_token || '';
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const [formData, setFormData] = useState({ name: '', countryCode: '' });
    const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState(false);
    const [disableButton, setDisableButton] = useState(true);

    /**
     * Handle the auto complete change
     * @param newVal 
     */
    const handleChange = (newVal: any) => {
        const name = 'countryCode'
        const value = newVal ? newVal.code : ''
        setFormData({ ...formData, [name]: value });
    }

    /**
     * Handle the name change
     * @param event 
     */
    const handleNameChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData({ ...formData, [name]: value });
    }

    /**
     * Perform the name validation
     */
    const formValidation = () => {
        const name = formData.name.trimEnd();
        const validName = validateName(name);
        const validCountry = formData.countryCode !== '';
        setDisplayNameErrorMessage(!validName);
        setDisableButton(!validName || !validCountry)
    }

    /**
     * Perform the creation of configuration and rediration to the configuraion page.
     */
    const createConfig = () => {
        const name = formData.name;
        dispatch(createConfiguration({
            token: token,
            request: { productId: productId, country: formData.countryCode, name: name.trim().length <= 0 ? null : name, fulfilmentDate: new Date(), state: EConfigurationType.Temp }
        })).unwrap().then((res) => {
            if (res.error) {
                dispatch(setError(res.error));
            } else {
                dispatch(onLoadCheck({ key: false }));
                dispatch(changeLandingTab({ value: true, tabValue: ETabValue.Product }));
                navigate('/?' + EUrlParams.ConfigurationId + '=' + res.configurationId, { replace: true });
            }
        })
        closeDialog();
    }

    /**
     * Call Validation
     */
    useEffect(() => {
        formValidation();
    })

    return <>
        <Dialog open={showDialog} PaperComponent={DraggableComponent} aria-labelledby="draggable-dialog-title" className="create-configuration-dialog common-dialog-style cursor-move">
            <DialogTitle className="header title">
                <b> {productId} </b>{` - ${dialogProdDesc}`}
            </DialogTitle>
            <DialogContent sx={{ paddingTop: '1em' }}>
                <FormControl fullWidth className="form-control" size="small" >
                    <Autocomplete
                        className="countryCode"
                        options={countriesAvailable ? countriesAvailable : []}
                        onChange={(_, newVal) => handleChange(newVal)}
                        getOptionLabel={(option) => option ? option.name + ` (${option.code})` : ''}
                        renderOption={(optionProps, option) =>
                            <Box component="li" {...optionProps}>
                                {option.name} ({option.code})
                            </Box>
                        }
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                className="Country-Label"
                                label={t('labels.country') + ' *'}
                            />}
                    />

                </FormControl><br /><br />

                <TextField
                    fullWidth
                    name="name"
                    label={t('labels.configurationName')}
                    placeholder={'(' + t('labels.optional') + ')'}
                    size="small"
                    value={formData.name}
                    onChange={handleNameChange}
                /><br />
                <Collapse in={displayNameErrorMessage && formData.name.length > 0}>
                    <Alert className="errorMessage" severity="error">
                        <AlertTitle>{t('errorMessages.nameErrorMessage')} </AlertTitle>
                    </Alert>
                </Collapse>
                <br />
            </DialogContent>
            <DialogActions>
                <div className="formActionButtons">
                    <Button className="text-capitalize" onClick={closeDialog} data-testid="close-dialog">{t('button.cancel')}</Button>
                    <Button variant="contained" className="text-capitalize" disabled={disableButton} onClick={() => createConfig()} data-testid="create-configuration">{t('button.configure')}</Button>
                </div>
            </DialogActions>
        </Dialog>
    </>
}

export default CreateConfigurationForm;