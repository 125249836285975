import { AddCircleRounded, RemoveCircleRounded } from '@mui/icons-material';
import { Box, ClickAwayListener, FormHelperText, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { gridLayout } from '../SectionLayout';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IConfigurationValue, IConfigurationVariable, IExtendedConfigureResponse, INumericControlProps } from '../../../types';
import { getNumericValidationInfo } from '../../services/ValidationHelperFunctions';
import { OptionalControl,Price} from '..';
import { AppDispatch, AppStore } from '../../store';
import { useDispatch } from 'react-redux';
import { setConfigureAPI } from '../../store/states/ConfigurationSlice';
import { hasCompatibleValues } from '../../services/ConfigurationDataHelperFunctions';

type INumericInputValues = Omit<IConfigurationValue,'type'>&{type?:'SingletonValue'|'IntervalValue'|'BoundValue'};
const lowerbound = {name:'BoundValue',value:Number.MIN_SAFE_INTEGER} as INumericInputValues;
const upperbound = {name:'BoundValue',value:Number.MAX_SAFE_INTEGER} as INumericInputValues;

function getValidationInfo( variable:IConfigurationVariable, value:string ) {
  const validValues = variable.values.map( ( v )=>{
    if( !Object.prototype.hasOwnProperty.call( v, 'name' ) ) {
      return `[${v.lower} - ${v.upper}]`
    }else{
      return `${v.value}`
    }
  } )
  if( value === '' ) {
    return {valid:true,validValues}
  }
  const valueSplit = value.split( '.' );
  if( valueSplit.length === 2 && ( valueSplit[1].length === 0 || valueSplit[1].length > Number( variable.scale ) ) ) {
    return {valid:false,validValues}
  }
  return getNumericValidationInfo( variable,Number( value ) );
}

function getValue( value:INumericInputValues,lower = true ) {
  if( !Object.prototype.hasOwnProperty.call( value, 'name' ) ) {
    return lower ? Number( value.lower ) : Number( value.upper );
  }else{
    return Number( value.value );
  }
}
export function getLower( values:IConfigurationValue[] ) {
  let value = upperbound;
  values && Array.isArray( values ) && values.forEach( ( v )=>{
    const pv = getValue( value ),cv = getValue( v );
    if( !Number.isNaN( cv ) && pv >= cv ) {
      value = v;
    }
  } )
  return value.type !== 'BoundValue' ? getValue( value ) : Number.MIN_SAFE_INTEGER;
}
function getUpper( values:IConfigurationValue[] ) {
  let value = lowerbound;
  values && Array.isArray( values ) && values.forEach( ( v )=>{
    const pv = getValue( value,false ),cv = getValue( v,false );
    if( !Number.isNaN( cv ) && pv <= cv ) {
      value = v;
    }
  } )
  return value.type !== 'BoundValue' ? getValue( value,false ) : Number.MAX_SAFE_INTEGER;
}

function blockInvalidChar( e:React.KeyboardEvent<HTMLDivElement> ) {
  ['e', 'E', '+', '-'].includes( e.key ) && e.preventDefault();
}

function isValidAssignment( value:string,newValue:string,valid:boolean ) {
  let validAssignment = valid;
  if( valid && value && newValue && Number( value ) === Number( newValue ) ) {
    validAssignment = false;
  }
  return validAssignment;
}

function getUpdatedValue( value:string,increment:boolean,step:number ) {
  return Number( value ) + ( increment ? 1 : -1 ) * step;
}

function getButtonProps( value:string,lower:number,upper:number,step:number,disabled?:boolean ) {
  const decDisabled = Number( value ) - lower < step || !!disabled;
  const incDisabled = upper - Number( value ) < step || !!disabled;
  return {
    dec:{
      disabled:decDisabled,
      className:decDisabled ? 'btn-decrement-disabled' : 'btn-decrement'
    },
    inc:{
      disabled:incDisabled,
      className:incDisabled ? 'btn-decrement-disabled' : 'btn-increment'
    }
  }
}
function getMinValue( variable:IConfigurationVariable,newValue:number ) {
  const lowers:number[] = variable.values.map( v=> v.lower ? Number( v.lower ) : Number( v.value ) );
  lowers.sort( ( a:number,b:number )=>a - b );
  const low = lowers.find( l=>l > newValue ); 
  return low ? low : -1;
}
function getMaxValue( variable:IConfigurationVariable,newValue:number ) {
  const uppers:number[] = variable.values.map( v=> Number( v.upper ) );
  uppers.sort( ( a:number,b:number )=>b - a ) ;
  const max = uppers.find( l=>l < newValue ); 
  return max ? max : -1;
}
const onValueUpdate = ( increment:boolean,internalNumericValue:string,variable:IConfigurationVariable,step:number,setrangeValidity:( value:string )=>void,onInternalChange:( value:string )=>void ) => {
  let newValue:number = getUpdatedValue( internalNumericValue,increment,step )
  const currentValidity = getValidationInfo( variable,newValue.toString() );
  if( !currentValidity.valid ) {
    if( increment ) {
      newValue = getMinValue( variable,newValue );
      if( !newValue || newValue < 0 ) {
        newValue = getUpper( variable.values )
      }
    }else{
      newValue = getMaxValue( variable,newValue );
      if( !newValue || newValue < 0 ) {
        newValue = getLower( variable.values )
      }
    }
    setrangeValidity( getValidationInfo( variable,newValue.toString() ).validValues.join( ', ' ) );
  }else{
    setrangeValidity( '' );
  }
  onInternalChange( newValue.toString() );
}


/**
 * Renders an input box for numeric values.
 * Used for variables which allows the user to set custom numeric values
 * @param {INumericControlProps} props the properties for the numeric input component
 * @returns {JSX.Element} the numeric input component
 */
export const NumericControl = ( {
  featureFlags,
  variable,
  label,
  value,
  onChange,
  onCallback,
  subModelIcon,
  disabled,
  onOptionalChange
}: INumericControlProps ) => {
  const [internalNumericValue, setInternalNumericValue] = useState( value );
  const [rangeValidity, setrangeValidity] = useState( '' );
  const [valueNotUpdated, setValueNotUpdated] = useState( false );
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const step = 1;
  const isSubModel = !!subModelIcon;
  const lower = getLower( variable.values );
  const upper = getUpper( variable.values );
  const config = AppStore.getState().configuration as IExtendedConfigureResponse;
  const upperValue = upper.toString()?.length;
  const backgroundClass = !hasCompatibleValues( variable ) ? 'incompatible':'';
  
  useEffect( () => {
    setInternalNumericValue( value );
    dispatch( setConfigureAPI( {value: false} ) )
  }, [valueNotUpdated,value,config.configureApiCall] )

 
  if( !variable ) {
    return null;
  }
  const validity = internalNumericValue !== value ? getValidationInfo( variable, `${internalNumericValue}` ) : {valid:true,validValues:[`[${lower} - ${upper}]`]};

  const onEventChange = ( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    setrangeValidity( '' );
    onInternalChange( e.target.value );
  };

  const onInternalChange = ( newValue: string ) => {   
    setInternalNumericValue( newValue );
    const validAssignment = getValidationInfo( variable,`${newValue}` ).valid;
    const shouldUpdate = isValidAssignment( `${value}`,`${newValue}`,validAssignment );
    onCallback && onCallback( validAssignment );
    onChange && onChange( `${newValue}`, shouldUpdate, null );
  }

  const onLeave = ( e : any ) => {
    setrangeValidity( '' );
    if( !getValidationInfo( variable, `${internalNumericValue}` ).valid && !e.relatedTarget ) {
      setValueNotUpdated( !valueNotUpdated );
    }
  }

  const validityHintText = t( 'errorMessages.numberInvalid',{value:internalNumericValue,range:validity.validValues.join( ', ' )} );
  const buttonProps = getButtonProps( internalNumericValue,lower,upper,step,disabled )
  return <>
    <Grid container className={ backgroundClass } item xs={ gridLayout.fullWidth } alignItems="center">
      <ClickAwayListener onClickAway={ onLeave }>
        <Grid className="clickAway" container item xs={ gridLayout.xs.input } sm={ gridLayout.sm.input } justifyContent="left" alignItems="center" onBlur={ onLeave }>
          <IconButton className="iconCheck" disabled={ buttonProps.dec.disabled } onClick={ ()=>onValueUpdate( false, internalNumericValue,variable,step,setrangeValidity,onInternalChange ) }>
            <RemoveCircleRounded className={ `${buttonProps.dec.className}` }/>
          </IconButton>
          <TextField
            key={ `numeric-input-${label}` }
            className={ 'numericInputField txt-field' }
            color="primary"
            type="number"
            value={ internalNumericValue }
            onChange={ onEventChange }
            onKeyDown={ blockInvalidChar }
            inputProps={ {title:'', style : {width:`${upperValue > 5 ? upperValue + 0.5 : 5 }ch`}} }
            disabled={ disabled }
          />
          <IconButton className="updateIcon" data-testid="updateIcon" disabled={ buttonProps.inc.disabled } onClick={ ()=>onValueUpdate( true,internalNumericValue,variable,step,setrangeValidity,onInternalChange ) }>
            <AddCircleRounded className={ `${buttonProps.inc.className}` }/>
          </IconButton>
        </Grid>
      </ClickAwayListener>
      {
        isSubModel 
          ? <Grid container item xs={ gridLayout.xs.infoIcon } sm={ gridLayout.sm.infoIcon } alignItems="center" justifyContent="center">
            {subModelIcon()}
          </Grid>
          : <><Grid container item xs={ gridLayout.xs.infoIcon } sm={ gridLayout.sm.infoIcon } alignItems="center" justifyContent="center"/>
            <Grid container item xs={ gridLayout.xs.optional } sm={ gridLayout.sm.optional } alignItems="center" justifyContent="center">
              <OptionalControl variable={ variable } onOptionalChange={ onOptionalChange } featureFlag = { featureFlags?.Optional }/>
            </Grid>
            <Grid container item xs={ gridLayout.xs.price } sm={ gridLayout.sm.price } alignItems="right" justifyContent="right">
              <Box display={ {xs:'inline',sm:'none'} } textAlign="center">{t( 'labels.listPrice' )}:&nbsp;</Box>
              <Price price={ variable.price } isBundled={ variable.isBundled }/>
            </Grid>
          </>
      }
      { 
        validity.valid
          ? null 
          : <Tooltip title={ validityHintText }>
            <FormHelperText className="validation-error">{ validityHintText }</FormHelperText>
          </Tooltip> 
      }
      {
        rangeValidity ? <Tooltip title={ validityHintText }>
          <FormHelperText className="validation-error success">{t( 'errorMessages.rangeMessage' )}: { rangeValidity }</FormHelperText>
        </Tooltip> : null
      }
    </Grid>
  </>;
};