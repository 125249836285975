import { IFeatureFlags } from '../../types';

export const DefaultFlow = 'complete';

export const NaNCurrencyCode = 'NaN';

export const DefaultLanguage = {
  Id: 'en-US',
  Value: [{ code: 'en-US', name: 'English' }]
}

export const ModelInclusion = {
  Id: 'MODEL_INCLUSION',
  Included: 'INCLUDED'
}

export const ApplicationSupportedLanguages = [
  {
    'name': 'English',
    'code': 'en-US'
  },
  {
    'name': 'German',
    'code': 'de-DE'
  },
  {
    'name': 'French',
    'code': 'fr-FR'
  },
  {
    'name': 'Italian',
    'code': 'it-IT'
  }
]

export const ProductScope = {
  BuildDateId: 'DIM_BUILDDATE',
  EventId: 'EVENT',
  MarketId: 'MRKT',
}

export const UserAssignmentsScope = [ProductScope.BuildDateId, ModelInclusion.Id, ProductScope.MarketId];

export const ProductSection = {
  HiddenSection: '_HIDDEN_'
}

export const IdFormat = {
  Suffix: {
    SubmodelId: '_C',
    SubmodelVariable: 'S',
    LessFeature: '_LESS',
    Role: 'ROLE'
  },
  Regex: {
    ScopeSection: /Scope\d+$/i
  }
}

export const PriorityValue = {
  High: 999,
  Medium: 998,
  Normal: 997
}

export const ScopeSectionVariableIds: string[] = [ProductScope.BuildDateId, ProductScope.EventId, ProductScope.MarketId];

//array of variableIds whose name is changed
export const ChangedNameVariableIds: string[] = [ProductScope.BuildDateId, ProductScope.MarketId];

export const PopperModifier = [
  {
    name: 'flip',
    enabled: false,
    options: {
      altBoundary: false,
      rootBoundary: 'viewport',
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      altAxis: true,
      altBoundary: true,
      tether: true,
      rootBoundary: 'document',
    },
  },
]

export const DefaultUserSettings = {
  language: DefaultLanguage.Id,
  showCode: true,
}

export const FeatureFlags: IFeatureFlags = {
  Currency: { name: 'Currency', active: false, visible: false, editable: false },
  Language: { name: 'Language', active: false, visible: false, editable: false },
  Optional: { name: 'Optional', active: false, visible: false, editable: false },
  ListPrice: { name: 'ListPrice', active: false, visible: false, editable: false },
  ExportConfiguration: { name: 'ExportConfiguration', active: false, visible: false, editable: false },
  DetailedConfiguration: { name: 'DetailedConfiguration', active: false, visible: false, editable: false },
  PriceDisclaimer: { name: 'PriceDisclaimer', active: false, visible: false, editable: false },
  SimpleConfigurationSummary: { name: 'SimpleConfigurationSummary', active: false, visible: false, editable: false }
}

export const RecordsPerPage = [15, 25, 50, 100];

export enum EConfigItProperty {
  ReadOnly = 'REO',
  Hide = 'HDE',
  SubModelQty = 'SMQ',
  BundleContent = 'BCT',
  IsOptional = 'OPT',
  ProcosMarketAvailable = 'PMA',
  RulesMarketAvailable = 'RMA',
}

export enum EPDMProperty {
  OrderingInstructions = 'ORI',
  MaxLength = 'MLE',
  IsServiceRelevant = 'SRI',
  Guarded = 'GRD',
  IsPriceRelevant = 'IPR',
}

//Below properties should use PDM property values instead of Configit
export const PdmProperties = [EPDMProperty.MaxLength, EPDMProperty.IsServiceRelevant, EPDMProperty.OrderingInstructions, EPDMProperty.Guarded, EPDMProperty.IsPriceRelevant];

export const ConfigitProperties = [EConfigItProperty.ReadOnly, EConfigItProperty.Hide, EConfigItProperty.IsOptional, EConfigItProperty.ProcosMarketAvailable, EConfigItProperty.RulesMarketAvailable]

export enum EConfigurationType {
  Active = 'Active',
  ReadOnly = 'ReadOnly',
  Temp = 'Temp',
}

export enum EJustification {
  Phase = 'Phase',
  Default = 'Default',
  None = 'None',
  Assignment = 'Assignment',
  Rule = 'Rule'
}

export enum EVariableType {
  Submodel = 'Submodel',
  Date = 'Date',
  Number = 'Number',
  String = 'String',
  Multiselect = 'Multiselect',
  Singleselect = 'Singleselect',
  Boolean = 'Boolean',
  Product = 'PM'
}

export enum EBrowserType {
  Chrome = 'Chrome',
  Edge = 'Edge',
  Safari = 'Safari',
}

export enum EUrlParams {
  ConfigurationId = 'cid',
  Scope = 'cc',
  Token = 'at',
  Language = 'l',
  Country = 'c',
  Currency = 'cur',
  HeaderLess = 'hl',
  ViewId = 'viewId',
  Model = 'model',
  Page = 'Page',
  Limit = 'Limit',
  ProductSearch = 'search'
}

export enum QueryParamsValue {
  Long = 'long'
}

export enum ApiUrl {
  SalesTextApi = '/product/v1/salestext/read',
  ImagesApi = '/product/v1/images/read'
}

export const HideLoaderApi = [ApiUrl.ImagesApi, ApiUrl.SalesTextApi];

export enum EActionType {
  Notify = 'notify',
  Reset = 'reset',
  ClearAll = 'clearAll',
  CloseWithoutSaving = 'closeWithoutSaving',
  Success = 'success',
  IncompleteConfiguration = 'incompleteConfiguration'
}

export enum ESessionStore {
  ConfigurationId = 'configurationId',
  ViewId = 'viewId',
  ProductId = 'productId',
  Scope = 'scope',
  Flow = 'flow',
  IsGuardedTab = 'isGuardedTab',
  PriceListMap = 'priceListMap',
  Language = 'language',
  Country = 'country',
  Currency = 'currency',
  Token = 'token',
  HeaderLess = 'headerLess',
  SearchCount = 'searchCount',
  SearchMatches = 'searchMatches',
  Channel = 'channel'
}

export enum ELocalStore {
  IsUnSupportedBrowserAck = 'isUnsBrowAck'
}

export enum ESectionId {
  Guarded = 'guarded'
}

export enum EChangeType {
  Remove = 'Remove',
  Change = 'Change',
  Add = 'Add'
}

export enum ESectionInfoType {
  Invalid = 'invalid',
  Scope = 'scope',
  Model = 'model',
  Section = 'section'
}

export enum ETabValue {
  Information = 'Information',
  Attention = 'Attention',
  SalesText = 'SalesText',
  OrderingInstructions = 'OrderingInstructions',
  Images = 'Images',
  Product = 'Product',
  MyConfiguration = 'MyConfiguration'
}

export enum EDeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet'
}

export enum EErrorCodes {
  Unauthenticated = 401,
  Unautorized = 403
}

export enum EErrorTypes {
  NotAuthenticated = 'NotAuthenticated',
  NotAuthorized = 'NotAuthorized',
  Unsupported = 'Unsupported',
  ContactUS = 'ContactUS',
  InvalidConfiguration = 'InvalidConfiguration'
}

export enum EPageNames {
  LandingPage = '/landingPage',
  Notified = '/notified',
  CloseTab = '/closeTab',
  ErrorPage = '/Error',
  Logout = '/logout'
}

export enum EEnvironments {
  DEV = 'DEV',
  PROD = 'PROD',
  TEST = 'TEST',
  ACC = 'ACC',
}

export enum EMessagesType {
  Warning = 'Warn'
}


export const enum EFilterRootOption {
  Channel = 'Channels',
  Application = 'Applications',
  Business = 'Business'
}

export const enum EErrorPage {
  Configurator = 'Configurator'
}

export enum EPropertyType {
  Bool = 'BOOL',
  Enum = 'ENUM',
  String = 'STR',
  Numeric = 'NUM'
}
