import { IApiError, IConfigurationIdRequest } from '../../types/IApiTypes';
import AxiosClient from './AxiosClient';
import { getHeaders, handleError } from './HttpUtil';


export const NotifyAPI = {
  notify: ( action: IConfigurationIdRequest ) =>
    AxiosClient
      .post( '/configurator/v1/configuration/notify/save',
        { configurationId: action.configurationId },
        { headers: getHeaders( action.token ).headers } 
      ).catch( ( err:IApiError ) => {
        return handleError( err, 'notify' );
      } ),

  reset: ( action: IConfigurationIdRequest ) =>
    AxiosClient
      .post( '/configurator/v1/configuration/reset',
        { configurationId: action.configurationId },
        { headers: getHeaders( action.token ).headers }
      )

};
