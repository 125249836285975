import React, { memo, useState } from "react";
import { SimpleTreeView, useTreeViewApiRef } from '@mui/x-tree-view';
import TreeItemGroupComponent from "./TreeItemGroupComponent";
import { IFilterOption, ISimpleTreeProps, ITreeExand, ISimpleTreeNode } from "../../types";
import AutoCompleteControl from "./InputComponents/AutoCompleteControl";
import { getSelectedCodeNames, handleSelectionOfMultiSelectOnChange } from "../store/Helpers/LandingHelper";
import { IconButton } from "@mui/material";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { useTranslation } from "react-i18next";
/**
 * This component responsible for creating filter tree based on the provided schima
 * @props filterTreeProps 
 * @callback handleChanges
 * @returns {JSX.Element} a  SimpleTreeView component or span element.
 */
const SimpleTreeViewComponent = ({ filterTreeProps, handleChanges }: { filterTreeProps: ISimpleTreeProps, handleChanges: (selectedCodes: IFilterOption, type: string) => void }) => {
    let { treeNodes, availableFilterCodes, filteredCodes, initialExpandValue, filterDataAvailable } = filterTreeProps;
    const apiRef = useTreeViewApiRef();
    const { t } = useTranslation();
    const [expandedTree, setExpandedTree] = useState<ITreeExand>(initialExpandValue);
    const [itemNameForShow, setItemNameForShow] = useState<IFilterOption>(filteredCodes);

    /**
     * Clear the selected filter check
     * @param type 
     * @returns void
     */
    const handleClearClick = (type: string): void => {
        let copyFiltedCodes: IFilterOption = { ...filteredCodes };
        copyFiltedCodes[type] = [];
        filteredCodes = copyFiltedCodes;
        handleChanges(copyFiltedCodes, type);
    };

    /**
     * Call on selection or deselction of filter checkbox
     * @param itemIds 
     * @param treeNode 
     * @returns void
     */
    const handleItemChange = (itemIds: Array<string>, treeNode: ISimpleTreeNode): void => {
        const selectedCodes = handleSelectionOfMultiSelectOnChange(itemIds, treeNode, filteredCodes, availableFilterCodes);

        const nameOfSelectedCodes = getSelectedCodeNames(treeNode, selectedCodes);
        const names = { ...itemNameForShow, [treeNode.type]: nameOfSelectedCodes };
        setItemNameForShow(names);
        handleChanges(selectedCodes, treeNode.type);
    }

    /**
     * Expand and collapse the filter view
     * @param isExpanded 
     * @param type 
     * @returns void
     */
    const handleItemExpansion = (isExpanded: boolean, type: string): void => {
        if (isExpanded) {
            setExpandedTree({ ...expandedTree, [type]: true });
        } else {
            setExpandedTree({ ...expandedTree, [type]: false });
        }
    }

    return (
        <>
            {!filterDataAvailable ? <span className="noDataAvailable-label" >{t('labels.noDataAvailable')}</span> :
                treeNodes.map((treeNode: ISimpleTreeNode) => (
                    <SimpleTreeView key={treeNode.type}
                        multiSelect checkboxSelection apiRef={apiRef}
                        onSelectedItemsChange={(event: React.SyntheticEvent, itemIds: Array<string>) => handleItemChange(itemIds, treeNode)}
                        defaultExpandedItems={[treeNode.expanded]}
                        selectedItems={filteredCodes[treeNode.type]}
                        onItemExpansionToggle={(event: React.SyntheticEvent, itemId: string, isExpanded: boolean) => { handleItemExpansion(isExpanded, treeNode.type) }}
                        className="filter-treeview">
                        {treeNode.treeItems.length > 0 && <TreeItemGroupComponent key={treeNode.type} name={treeNode.label} code={treeNode.enumValue} treeItems={treeNode.treeItems}>
                            {filteredCodes[treeNode.type].length > 0 && <IconButton aria-label="filter" onClick={() => { handleClearClick(treeNode.type) }} className='filter-button'>
                                <FilterAltOffIcon />
                            </IconButton>}
                        </TreeItemGroupComponent>}
                        {filteredCodes[treeNode.type].length > 0 && !expandedTree[treeNode.type] && <AutoCompleteControl options={itemNameForShow[treeNode.type]} disabled={true} id={`test-id-${treeNode.type}`} />}
                    </SimpleTreeView>
                ))}
        </>
    )
}
export default memo(SimpleTreeViewComponent);