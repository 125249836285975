import configurationReducer from './states/ConfigurationSlice';
import { configureStore } from '@reduxjs/toolkit'
import userSettingsReducer from './states/UserSettingsSlice';
import busyReducer from './states/BusySlice';
import applicationSettingsReducer from './states/ApplicationSettingsSlice';
import errorReducer from './states/ErrorSlice';
import userPreferencesReducer from './states/UserPreferencesSlice';
import claimsDataReducer from './states/ClaimsDataSlice';
import messagesReducer from './states/MessagesSlice';
import userReducer from './states/UserSlice';
import productCatalogReducer from './states/ProductCatalogSlice';

export const AppStore = configureStore (
  {
    reducer : {
      user:userReducer,
      busy: busyReducer,
      configuration: configurationReducer,
      userSettings: userSettingsReducer,
      error:errorReducer,
      applicationSettings:applicationSettingsReducer,
      messagesState: messagesReducer,
      claimsData:claimsDataReducer,
      userPreferences:userPreferencesReducer,
      productCatalog:productCatalogReducer
    },
    middleware:( getDefaultMiddleware ) => {
      return getDefaultMiddleware( {
        serializableCheck: false
      } )
    }
  } )

export type AppDispatch = typeof AppStore.dispatch
export type AppState = ReturnType<typeof AppStore.getState>;
