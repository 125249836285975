import { createSlice } from '@reduxjs/toolkit';
import { DefaultLanguage } from '../../data/Constants';
import { SessionStore } from '../../services/SessionStore';

const sessionStoreKey = 'user-settings';


const getDefaultSettings = () => {
  //The below state variable values are used in action panel to update the current language and show code icon 
  //If any values are updated in Userpreference dialog, the below state values also will be update
  const stateProps = { 
    language: DefaultLanguage.Id,
    showCode: true
  }
  const storedSettings = SessionStore.get( sessionStoreKey );
  let userSettings;
    
  if( !storedSettings ) {
    userSettings = stateProps;
  } else {
    for( const key in stateProps ) {
      if( !( key in storedSettings ) ) {
        //set default settings if stored settings are missing data
        userSettings = stateProps;
        break;
      }
    }
    userSettings = userSettings || storedSettings;
  }  
  return userSettings ;
}

const initialState = getDefaultSettings();


const userSettingsSlice = createSlice( {
  name: 'userSettings',
  initialState,
  reducers: {
    setUserSettings( state, action ) {
      if( Object.keys( action.payload ).includes( 'showCode' ) ) {
        state.showCode = action.payload.showCode
      } else if( action.payload?.language ) {
        state.language = action.payload.language
      }
    },
    resetUserSettings( ) {
      return initialState;
    }
  }
} )

export const { setUserSettings, resetUserSettings } = userSettingsSlice.actions;


export default userSettingsSlice.reducer

