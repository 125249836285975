import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DefaultUserSettings } from '../../data/Constants';
import { UserSettingsApi } from '../../api/UserSettingsApi';
import { IUserPreferences } from '../../../types';
import { IAuthorizationRequest, IUserPreferenceRequest } from '../../../types/IApiTypes';

//The below values are used in userpreference dialog to set the default value
//After update any value in userpreference slice then those values will be update in usersettings slice
const initialState: IUserPreferences = {
  ...DefaultUserSettings,
  preferenceChanged: false,
  isUserSettingsDefault: false
};

//Gets User preferences 
export const getUserPreferences = createAsyncThunk( 'userPreferences/getUserPreferences', async ( action:IAuthorizationRequest )=>{
  return UserSettingsApi.getUserPreferences( action );
} );

//Updates user preferences
export const updateUserPreferences = createAsyncThunk( 'userPreferences/updateUserPreferences', async ( action:IUserPreferenceRequest )=>{
  return UserSettingsApi.updateUserPreferences( action );
} );


const userPreferencesSlice = createSlice( {
  name: 'userPreferences',
  initialState,
  reducers: {
    setUserPreferences( state, action ) {
      return { ...state, ...action.payload }
    }
  },
  extraReducers: ( builder ) => {
    builder.addCase( getUserPreferences.fulfilled, ( state, action ) => {
      if( action.payload.data ) {
        return updateState( state, action );
      } else {
        state.isUserSettingsDefault = true;
      }
      
    } );
    builder.addCase( updateUserPreferences.fulfilled, ( state, action ) => {
      return updateState( state, action );
    } );
  }
} )

function updateState( state, action ) {
  if ( action.payload?.data ) {
    return { ...state, ...action.payload.data }
  }
  return state;
}

export const { setUserPreferences } = userPreferencesSlice.actions;


export default userPreferencesSlice.reducer

