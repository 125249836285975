import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../AppStore';
import { ProductApi } from '../../api/ProductApi';
import { productCatalogData } from '../Helpers/ProductSettingsSliceHelper';
import { IAuthorizationRequest, IProductCatalogRequest } from '../../../types/IApiTypes';
import { IProductPortFolio } from '../../../types/ITypes';

const initialState: IProductPortFolio = {
  productCatalog: {},
  productCatalogDetails: { page: 1, limit: 15, totalRecords: 1 },
  productFilterOptions: { channels: [], business: [] },
  loading: false
}

//Gets the product model details
export const getProductCatalog = createAsyncThunk('productCatalog/getProductCatalog', async (payload: IProductCatalogRequest) => {
  return ProductApi.productCatalog(payload);
});

//Gets product hierarchy
export const getProductFilter = createAsyncThunk('productCatalog/getProductFilter', async (payload: IAuthorizationRequest) => {
  return ProductApi.productFilter(payload);
});


const productCatalogSlice = createSlice({
  name: 'productCatalog',
  initialState: initialState,
  reducers: {
    setProductCatalogDetails(state, action) {
      const { page, limit, totalRecords } = action.payload;
      state.productCatalogDetails = { page: page, limit: limit, totalRecords: totalRecords };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProductCatalog.fulfilled, (state, action) => {
      if (action.payload) {
        const data = productCatalogData(action.payload, state);
        state.productCatalog = data.productCatalog;
        state.productCatalogDetails = data.productCatalogDetails;
      }
    });

    builder.addCase(getProductFilter.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getProductFilter.fulfilled, (state, action) => {
      if (action.payload) {
        const { productFilterOptions }: any = action.payload;
        state.productFilterOptions = productFilterOptions;
        state.loading = false;
      }
    });

  }
})

export const { setProductCatalogDetails } = productCatalogSlice.actions;

export const productCatalog = (state: AppState) => state;

export default productCatalogSlice.reducer;
