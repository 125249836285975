import {
  Box,
  Container,
  Divider,
  Theme,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  ActionPanel,
  BreadcrumbBar,
  Header,
  Home,
  LoadingScreen
} from '.';
import { AppDispatch, AppState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Footer } from './Footer';
import { LandingPage } from './Landing/LandingPage';
import { ErrorDialog } from './AppError';
import { isMobile, GetToken } from '../services';
import { EUrlParams, EErrorTypes, ESessionStore } from '../data/Constants';
import { ErrorPage } from './ErrorPage';
import { SessionStore } from '../services/SessionStore';
import { Logout } from './Logout';
import { setApplicationSettings } from '../store/states/ApplicationSettingsSlice';

/**
 * Main component to handle the layout at the top level.
 * Sets the different routes.
 * Sets the current page size in the userSettingsState.
 * @returns {JSX.Element} a container with the UI components at top level
 */
function checkScope(navigate, token) {
  if (token && SessionStore.get(ESessionStore.ConfigurationId) && SessionStore.get(ESessionStore.Scope) === null) {
    navigate('/Error', { replace: true, state: { type: EErrorTypes.NotAuthorized } });
  }
}

export function AppRouter() {
  let currentPageSize = {
    isExtraSmall: useMediaQuery((theme: Theme) => theme.breakpoints.up('xs') && theme.breakpoints.down('sm')),
    isSmall: useMediaQuery((theme: Theme) => theme.breakpoints.up('xs') && theme.breakpoints.down('sm')),
    isMedium: useMediaQuery((theme: Theme) => theme.breakpoints.up('sm') && theme.breakpoints.down('md')),
    isLarge: useMediaQuery((theme: Theme) => theme.breakpoints.up('lg') && theme.breakpoints.down('xl')),
    isExtraLarge: useMediaQuery((theme: Theme) => theme.breakpoints.up('xl')),
  }
  const mobilePageSize = {
    isExtraSmall: useMediaQuery((theme: Theme) => theme.breakpoints.up('xs') && theme.breakpoints.down('sm')),
    isSmall: useMediaQuery((theme: Theme) => theme.breakpoints.up('sm') && theme.breakpoints.down('md')),
    isMedium: useMediaQuery((theme: Theme) => theme.breakpoints.up('md') && theme.breakpoints.down('lg')),
    isLarge: useMediaQuery((theme: Theme) => theme.breakpoints.up('lg') && theme.breakpoints.down('xl')),
    isExtraLarge: useMediaQuery((theme: Theme) => theme.breakpoints.up('xl')),
  }
  if (isMobile()) {
    currentPageSize = mobilePageSize
  }
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = GetToken();
  const dispatch = useDispatch<AppDispatch>();

  const applicationSettings = useSelector((state: AppState) => state.applicationSettings)

  useEffect(() => {
    if (JSON.stringify(applicationSettings.pageSize) === JSON.stringify(currentPageSize)) {
      return;
    }
    dispatch(setApplicationSettings({ pageSize: currentPageSize }));
  });

  useEffect(() => {
    checkScope(navigate, token);
  }, [token])

  return (
    <Container maxWidth={false} className="appRouter root">
      <Header />
      <Box className="secondaryHeader">
        <BreadcrumbBar />
        <ActionPanel />
      </Box>
      <Divider className="divider" />
      <Box className="appRouterContent">
        {location.search && location.search !== '?' + EUrlParams.ConfigurationId + '=null' ?
          <Routes>
            <Route caseSensitive path="/Error" element={<ErrorPage />} />
            <Route path="/Logout" element={<Logout />} />
            <Route path="/signin/oidc" element={<Home />} />
            <Route path="/signin/oidc-silent" element={<Home />} />
            <Route path="/landingPage" element={<LandingPage />} />
            <Route path="/" element={<Home />} />
            <Route path="/notified" element={<h4>
              {t('successMessages.notifySuccessMessage')}
            </h4>}
            />
            <Route path="/Error" element={<ErrorPage />} />
            <Route element={<Navigate to="/" replace={true} />} />
          </Routes>
          : <><Routes>
            <Route path="/Logout" element={<Logout />} />
            <Route caseSensitive path="/landingPage" element={<LandingPage />} />
            <Route path="/Error" element={<ErrorPage />} />      <Route path="/notified" element={<h4>
              {t('successMessages.notifySuccessMessage')}
            </h4>}
            />
            <Route caseSensitive path="/Error" element={<ErrorPage />} />
            <Route path="*" element={<Navigate to="/landingPage" replace={true} />} />
            <Route path="/closeTab" element={<h4>
              {t('successMessages.closeTabMessage')}
            </h4>}
            />
          </Routes></>}
      </Box>
      <LoadingScreen />
      <ErrorDialog />
      <Footer />

    </Container>
  );
}