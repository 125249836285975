import { createSlice } from '@reduxjs/toolkit';
import { ESessionStore } from '../../data/Constants';
import { SessionStore } from '../../services/SessionStore';
import { AppState } from '../AppStore';
import { IApplicationSettings } from '../../../types';

const params = new URLSearchParams( window.location.search );
const configurationId = params.get( 'configurationId' );
const sessionStoreKey = 'application-settings';

const defaultApplicationSettings: IApplicationSettings = {
  showSectionTabs: true,
  showSummary: true,
  activeTab: 0,
  forceShowSectionTabs: false,
  forceShowSummary: false,
  pageSize: {
    isExtraSmall: false,
    isSmall: false,
    isMedium: false,
    isLarge: false,
    isExtraLarge: false
  },
  currency: {
    'countryCode': 'US',
    'name': 'United State Dollar',
    'currencyCode': 'USD',
    'locale': 'en-US'
  },
  isConfigurationChanged: false,
  priceListMap: 'US',
  productsActiveTab: {},
  searchCount: { searchCount: 0 },
  searchMatches: []
}

const getDefaultSettings = () => {
  let storedSettings = SessionStore.get( sessionStoreKey );

  if ( SessionStore.get( ESessionStore.ConfigurationId ) !== configurationId ) { //reset application setting for new confguration id
    SessionStore.remove( ESessionStore.ViewId );
    return defaultApplicationSettings;
  }
  if ( !storedSettings ) {
    storedSettings = defaultApplicationSettings;
  } else {
    let flag = false;
    for ( const key in defaultApplicationSettings ) {
      if ( !( key in storedSettings ) ) {
        flag = true;
        //set default settings if stored settings are missing any property
        storedSettings[key] = defaultApplicationSettings[key as keyof IApplicationSettings]; // add missing property in stored settings with default value

      }
    }
    if ( flag ) { // if property is missing then only update it in session store
      SessionStore.set( sessionStoreKey, storedSettings );
    }
  }
  return { ...storedSettings, forceShowSectionTabs: false, forceShowSummary: false };
}

const initialState = getDefaultSettings();

const applicationSettingsSlice = createSlice( {
  name: 'applicationSettings',
  initialState,
  reducers: {
    setApplicationSettings( state, action ) {
      state = {...state, ...action.payload}
      SessionStore.set( sessionStoreKey, state );
      return state;
    },
    resetApplicationSettings( state ) {
      SessionStore.set( sessionStoreKey, defaultApplicationSettings );
      return defaultApplicationSettings;
    },
    getApplicationSettings( state ) {
      return state;
    },
    setActiveTab( state,action ) {
      state.productsActiveTab[`${action.payload.modelId}`] = {sectionId:action.payload.sectionID,tabIndex:action.payload.tabIndex}
      SessionStore.set( sessionStoreKey, state );
    },
    resetAllProductActiveTabs( state ) {
      state.productsActiveTab = {}
      SessionStore.set( sessionStoreKey, state ); 
    },
    setSearchValues( state,action ) {
      state.searchCount = action.payload.searchCount;
      state.searchMatches = action.payload.searchMatches;
    },
    setCurrencyCode( state, action ) {
      state.currency.currencyCode = action.payload.currencyCode;
      SessionStore.set( sessionStoreKey, state );
    },
    setIsConfigurationChanged( state, action ) {
      state.isConfigurationChanged = action.payload.isConfigurationChanged;
      SessionStore.set( sessionStoreKey, state );
    },
    setCurrentActiveTab( state, action ) {
      state.activeTab = action.payload.activeTab;
      SessionStore.set( sessionStoreKey, state );
    }
  }
} )

export const { setApplicationSettings, resetApplicationSettings, 
  getApplicationSettings, setActiveTab, setSearchValues, setCurrencyCode,
  setIsConfigurationChanged, setCurrentActiveTab, resetAllProductActiveTabs } = applicationSettingsSlice.actions

export const applicationSettings = ( state: AppState ) => state

export default applicationSettingsSlice.reducer