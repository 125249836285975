import React from "react"
import { FormControl, MenuItem, Select } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ISelectComponent } from "../../../types"
/**
 * 
 * @prop name, value, elementAttr, options
 * @callback onChangeCallBack
 * @returns {JSX.Element} a  FormControl of Slect component.
 */
export const SelectComponent = ({ name, value, elementAttr, options, onChangeCallBack }: ISelectComponent) => {
    const { t } = useTranslation();

    return (
        <>
            <FormControl size="small" className="" style={{ width: '4.5rem', marginLeft: '5px' }}>
                <Select
                    id={name}
                    name={name}
                    value={value}
                    onChange={(e) => onChangeCallBack(e)}
                    className={elementAttr}
                >
                    {options.map((option: string | number) => <MenuItem key={option} value={option}>{option}</MenuItem>)}
                </Select>
            </FormControl>
            <label>{t('labels.records')} :</label>
        </>

    )
}