import { getPackagePath } from '../services';
import { EActionType } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { getHeaders, handleError } from './HttpUtil';
import { IApiError, IClearConfigRequest, IConfigurationIdRequest, ICreateConfigurationRequest, IExcelRequest, ILoadConfigurationRequest, IOptionalRequest } from '../../types/IApiTypes';

export const ConfigurationApi = {
  getViewList : ( action: IConfigurationIdRequest ) =>{
    return AxiosClient
      .post( '/configurator/v1/configuration/views/read',
        { configurationId: action.configurationId },
        { headers: getHeaders( action.token ).headers }
      )
      .then( ( response )=> {
        return {
          data: response.data
        } 
      } )
      .catch( ( err: IApiError ) => {
        return handleError( err, 'noViews' );
      } )
  },

  loadConfiguration: ( action: ILoadConfigurationRequest ) =>
    AxiosClient
      .post( '/configurator/v1/configuration/read',
        { configurationId: action.configurationId, viewId: action.viewId },
        { headers: getHeaders( action.token, action.languageCode ).headers }
      )
      .then( ( response ) => ( {
        data: response.data,
        access: response.headers.access
      } ) ).catch( ( err: IApiError ) => {
        // To Dispatch Invalid View action on Failure response to Configure API
        return handleError( err, 'configure' );
      } ),
      
  configure: ( action: ICreateConfigurationRequest ) => {
    return AxiosClient
      .post(
        '/configurator/v1/configuration/configure',action.request,
        {
          params:{packagePath:getPackagePath( action.request.configureRequest?.line.productId )},
          headers:getHeaders( action.token, action.language ).headers
        }
      )
      .then( ( response ) => ( {
        configuration: response.data
      } ) ).catch( ( err: IApiError ) => {
        // To Dispatch Invalid View action on Failure response to Configure API
        return handleError( err, 'configure' );
      } )
  },

  getPMLanguages: ( action: IConfigurationIdRequest ) => {
    return AxiosClient
      .get(
        '/product/v1/languages',
        {
          params:{ configurationId:action.configurationId }, 
          headers: getHeaders( action.token ).headers
        }
      ).then( ( response ) => {
        return {
          pmLanguages: response.data
        }
      } ).catch( () => {
        //If PM languages failed, default or fall back language would be used
        return null
      } )
  },

  clearConfiguration: ( action: IClearConfigRequest ) => {
    return AxiosClient
      .post( '/configurator/v1/configuration/clear',
        {
          configurationId: action.configurationId,
          viewID: action.viewId
        },
        { headers : getHeaders( action.token ).headers }
      ).then( ( response ) => {
        return {
          configuration: response.data,
          apiType: EActionType.ClearAll
        }
      } ).catch( ( err:IApiError ) => {
        return handleError( err, '' );
      } )
  },

  createConfiguration: ( action: ICreateConfigurationRequest ) => {
    return AxiosClient
      .post(
        '/punchout/v1/configuration/create',
        action.request,
        { headers : getHeaders( action.token ).headers }
      ).then( ( response ) => {
        return {
          configurationId: response.data
        }
      } ).catch( ( err:IApiError ) => {
        return handleError( err, 'create-configuration', action.request );
      } )
  },

  exportConfigurationBomExcel: ( action: IExcelRequest ) => {
    return AxiosClient
      .post( '/report/configuration/bom/excel',
        { configurationId: action.configurationId, isMultipleSheet: action.isMultipleSheet },
        { 
          headers:getHeaders( action.token ).headers, 
          responseType:'arraybuffer'
        }
      ).then( ( response ) => {
        return response;
      } ).catch( ( err:IApiError ) => {
        return handleError( err, '', action?.request );
      } )
  },

  getClaims: ( action: IConfigurationIdRequest ) =>{
    return AxiosClient
      .get( '/configurator/v1/claims/read', 
        { headers: getHeaders( action.token ).headers }
      ).then( ( response ) => {
        return {
          claims: response.data
        }
      } ).catch( ( err:IApiError ) => {
        return handleError( err, 'claims' );
      } )
  },
  
  setOptionalItems: ( action: IOptionalRequest ) =>
    AxiosClient
      .post( '/configurator/v1/configuration/optional/set',
        { configurationId: action.configurationId, optionalItems: action.optionalItems} ,
        { headers: getHeaders( action.token ).headers }
      ).then( ( response ) => {
        return response;
      } ).catch( ( err:IApiError ) => {
        return handleError( err, '' );
      } )
};
