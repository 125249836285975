
import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState, AppStore } from '../store';
import { IApplicationSettings, IConfigurationVariable, IFeatureFlags, ISummaryVariableProps } from '../../types';
import { getFormattedPrice, getValueFromVariable, getSummaryVariableName, getSymbol, getFeatureValue, filterOptionalValues } from '../services';
import { EVariableType } from '../data/Constants';
import { isFeatureVisible } from '../services/ClaimsHelper';
import { isValidCurrency } from '../services/Price';

//Show or hide price based on price value, variable ,valueBundle and feature flag ListPrice
function showPrice(featureFlags: IFeatureFlags, price: number, variable: IConfigurationVariable, valueBundled?: boolean): boolean {
  if (!isFeatureVisible(featureFlags.ListPrice) || price === null) {
    return false;
  }
  if (price >= 0) {
    return true;
  }
  return valueBundled || !(variable.variableType === EVariableType.Singleselect || variable.variableType === EVariableType.Multiselect) && variable.isBundled;
}

//This method returns the component which shows the variable name (Feature family info)
//null will be returned if feature flag SimpleConfigurationSummary is active to hide the Feature family info
const getVariableNameComponent = (variableName: string, isSimpleConfigurationSummary: boolean) =>
  !isSimpleConfigurationSummary ?
    <Box className="section">
      <span className="content" color="text.secondary">
        {variableName}
      </span>
    </Box>
    : null;

//This method returns the content for feature family value
//If feature flag SimpleConfigurationSummary is active, method getFeatureValue called to format the feature value based on type
//If feature flag SimpleConfigurationSummary is not active, method will load the valueName as it is
const getTableCellContent = (valueName: string, variableName: string, variableType: string, isSimpleConfigurationSummary: boolean, showOptional: boolean) => {
  const variableValueClass = `variableName ${showOptional ? 'optionalValue' : ''}`;
  return isSimpleConfigurationSummary ?
    <>
      <span className="content" color="text.secondary">
        {variableName}
      </span>
      <span className={variableValueClass}>{getFeatureValue(valueName, variableType)}</span>
    </>
    :
    <span className={variableValueClass}>{valueName}</span>
    ;
};

//Returns the content for the price cell
const getPriceContent = (currency: any, price: number, applicationSettings: IApplicationSettings) =>
  <>
    <span className={!isValidCurrency(currency?.currencyCode) ? 'invalidCurrency' : 'content'} color="text.secondary">
      {currency?.currencyCode && getSymbol(currency?.countryCode, currency?.currencyCode)}
    </span>
    <span className="content" color="text.secondary">
      {applicationSettings?.currency.currencyCode && getFormattedPrice(price)}
    </span>
  </>
  ;

/**
 * Renders the variable name and price on assigned value.
 * @param {ISummaryVariableProps} props the properties for the variable summary component
 * @returns {JSX.Element} the variable summary component
 */
export const VariableSummary = ({ variable, isScopeVariable, showOptional = false, isSimpleConfigurationSummary }: ISummaryVariableProps) => {
  const showCode = useSelector((state: AppState) => state.userSettings.showCode)
  const applicationSettings = useSelector((state: AppState) => state.applicationSettings)

  const featureFlags = AppStore.getState().claimsData?.featureFlags;

  //removing optional values when show optional is disabled
  const tempVar = filterOptionalValues(variable, showOptional);
  const values = getValueFromVariable(tempVar, showCode);
  const currency = applicationSettings?.currency;
  //getting price only for non scope variables
  if (values.length === 0 || tempVar.isMarkedOptional && !showOptional) {
    return null;
  }

  //getting the name as expected to be displayed in the summary panel
  const variableName = getSummaryVariableName(tempVar, showCode, isSimpleConfigurationSummary);
  const variableNameComponent = getVariableNameComponent(variableName, isSimpleConfigurationSummary);

  return <Box className="variableSummary root">
    {variableNameComponent}
    <Table size="small" aria-label="Variable Summary Table">
      <TableBody>
        {values.map((ele) =>
          <TableRow key={ele?.name || '' + ele.price} className={isSimpleConfigurationSummary ? 'fullWidthborder' : ''}>
            <TableCell className="headTableCell" component="th" scope="row">
              {getTableCellContent(ele.name, variableName, variable.variableType, isSimpleConfigurationSummary, showOptional)}
            </TableCell>
            {showPrice(featureFlags, ele.price, variable, ele.isBundled) && !isScopeVariable &&
              <TableCell className="tableCell" align="right">
                {getPriceContent(currency, ele.price, applicationSettings)}
              </TableCell>
            }
          </TableRow>
        )}
      </TableBody>
    </Table>
  </Box>
}